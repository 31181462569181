


























































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import {
  Place,
  DecoContestResult,
  DecoContestResultEntry,
} from '@/game/infos/dataServerPackets';
import fb from '@/store/sf-firestore';
import userx from '@/store/modules/userx';
import DecoEntryForResult from './DecoEntryForResult.vue';
import { Dictionary } from '@/util/dictionary';
import { EditState } from '@/store/models.def';
import { SoundEfx } from '@/client/sound/SoundEfx';
import ItemButton from '../mainMenu/tools/inventory/ItemButton.vue';
import StateButton from '@/components/inputs/StateButton.vue';

@Component({
  components: {
    DecoEntryForResult,
    ItemButton,
    StateButton,
  },
})
export default class DecoContestResultPage extends Vue {
  public get winners() {
    const list: Dictionary<DecoContestResultEntry> = {};
    for (const place of this.places) {
      list[place] = this.result[place] || {
        uid: '',
        name: 'Loading...',
        discordId: '',
        decoSet: '',
        fishType: 0,
        skin: 0,
        numLikes: 0,
        numDislikes: 0,
      };
    }
    return list;
  }
  public get noms() {
    const list: Dictionary<DecoContestResultEntry> = {};
    for (const place of this.adminNoms) {
      list[place] = this.result[place] || {
        uid: '',
        name: 'Loading...',
        discordId: '',
        decoSet: '',
        fishType: 0,
        skin: 0,
        numLikes: 0,
        numDislikes: 0,
      };
    }
    return list;
  }
  public get claimed2(): Dictionary<EditState> {
    const claim = { ...this.claimed };
    const claimables = userx.inventory.claimables;
    if (claimables) {
      for (const id in claimables) {
        if (Object.prototype.hasOwnProperty.call(claimables, id)) {
          if (id.indexOf('decoContest-1') === 0) {
            if (claimables[id] === 'done') {
              const place = id.split('-')[2];
              claim[place] = 'done';
            }
          }
        }
      }
    }
    return claim;
  }
  public get submitLabel() {
    const labels = {
      active: 'Claim',
      sending: '',
      done: 'Claimed',
    };
    return labels[this.submitState];
  }
  public result: DecoContestResult = {
    1: null,
    2: null,
    3: null,
    4: null,
    5: null,
    a1: null,
    a2: null,
    a3: null,
  };

  public myPlace = '';
  public myAdminPlace = '';

  public places: Place[] = ['1', '2', '3', '4', '5'];
  public adminNoms: Place[] = ['a1', 'a2', 'a3'];
  public ordinals = {
    1: '1st',
    2: '2nd',
    3: '3rd',
    4: '4th',
    5: '5th',
    a1: '1st',
    a2: '2nd',
    a3: '3rd',
  };
  public names = {
    1: 'Loading...',
    2: 'Loading...',
    3: 'Loading...',
    4: 'Loading...',
    5: 'Loading...',
    a1: 'Loading...',
    a2: 'Loading...',
    a3: 'Loading...',
  };
  public discordIds = {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    a1: '',
    a2: '',
    a3: '',
  };
  public bpTexts = {
    1: '6,000',
    2: '3,000',
    3: '2,000',
    4: '1,000',
    5: '1,000',
    a1: '3,000',
    a2: '2,000',
    a3: '2,000',
  };
  public bps = {
    1: 6000,
    2: 3000,
    3: 2000,
    4: 1000,
    5: 1000,
    a1: 3000,
    a2: 2000,
    a3: 2000,
  };
  public claimed: Dictionary<EditState> = {};

  public submitState: EditState = 'active';

  public modalShow = false;
  public toClaim: Place = '1';

  public async mounted() {
    this.loadEntries();
  }
  public beforeDestroy() {}

  public async loadEntries() {
    const myUid = userx.uid;
    const result = (this.result = await fb.getDecoContestResult(1, true));
    this.myPlace = this.myAdminPlace = '';

    for (const place of this.places) {
      if (result[place]?.uid === myUid) {
        this.myPlace = place;
      }
      this.names[place] = result[place] ? result[place]!.name.trim() : 'Loading...';
      this.discordIds[place] = result[place] ? result[place]!.discordId : '';
    }
    for (const place of this.adminNoms) {
      if (result[place]?.uid === myUid) {
        this.myAdminPlace = place;
      }
      this.names[place] = result[place] ? result[place]!.name.trim() : 'Loading...';
      this.discordIds[place] = result[place] ? result[place]!.discordId : '';
    }
  }

  public onOpenClaim(place: Place) {
    this.toClaim = place;
    this.submitState = 'active';
    this.modalShow = true;
  }

  public async onClaim() {
    this.claimed[this.toClaim] = this.submitState = 'sending';

    try {
      await userx.claimDecoContestPrize('1-' + this.toClaim);
      this.claimed[this.toClaim] = this.submitState = 'done';
      new SoundEfx('buyChaching').play();
      this.modalShow = false;
    } catch (error) {
      this.$root.$emit('error', (error as any).message);
      this.submitState = 'active';
      delete this.claimed[this.toClaim];
    }
    this.claimed = { ...this.claimed };
  }
}
